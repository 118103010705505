import * as React from 'react';
import { useRouter } from 'next/router';

import { IS_BROWSER } from '@src/config/settings';
import { Index } from '.';

export interface RouteProps {}

/**
 * @name Route
 * @description Simple page to forward all referral links
 */
export const Route = (_props: RouteProps) => {
  // Hooks
  const { query, push } = useRouter();

  // Setup
  const { id, ...restParams } = query;

  // 🔌 Short Circuit
  if (!IS_BROWSER || !id) return <Index />;

  // Make sure to pass on all query params
  push({ pathname: '/', query: { referredBy: id, ...restParams } });

  return <Index />;
};

export default Route;
